import React, { useState, useEffect } from "react";

import axios from "axios";
import dayjs from "dayjs";

import "./botStatusCurrentBalance.css";
import { locale_opt } from "../../../common/Commons";

function BotStatus_new({ botStatus , exchangesSelect, botCnt, balanceStatus }) {

    //alert(exchangesSelect)
    //alert(JSON.stringify(balanceStatus))
    // edit button
    const [editButtons, setEditButtons] = useState([""]);

    const handleEdit = (id,mode) => {
        console.log("handleEdit mode :" + mode);
        console.log("handleEdit id :" + id);
        let newArr = [...editButtons]; 
        if ( mode === "edit" ) {
            newArr[id] = "edit"; 
            //alert(id)
            setEditButtons(newArr);
            
        }else{
           //alert(id)
          newArr[id] = "save"; 
          setEditButtons(newArr);
        }
       // replace e.trigger.value with whatever you want to change it to
       
      };

     function saveLeverage(id,input_id){

     }
     function saveTp(strategy_group,coin_type ,exchange_no,exchange_name,transaction_id,input_id){
        let value = document.getElementById(input_id).value
        if (window.confirm('Do you want set value ?')) {

            let value = document.getElementById(input_id).value

            if (exchange_name == "BINANCE" || exchange_name == "BITGET" ) {
                alert("This is not supported on Bitget !");
                return false;
            }

            if (value == ""  ) {
                alert("Please check the value !");
                return false;
            }
            
            const data = {
                bot_id: "f_bot",
                msg: "set_tp",
                strategy_group : strategy_group,
                coin_type:coin_type,
                exchange_no:exchange_no,
                exchange_name: exchange_name,
                value: value,
                transaction_id: transaction_id,
            };
            //alert(JSON.stringify(data))

            axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                alert(res.data.msg);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
            
        }

     }
     function saveSl(strategy_group,coin_type ,exchange_no,exchange_name,transaction_id,input_id){
        let value = document.getElementById(input_id).value
        if (window.confirm('Do you want set value ?')) {
            let value = document.getElementById(input_id).value
            
            if (exchange_name == "BINANCE" || exchange_name == "BITGET" ) {
                alert("This is not supported on Bitget !");
                return false;
            }

            if (value == ""  ) {
                alert("Please check the value !");
                return false;
            }
            
            const data = {
                bot_id: "f_bot",
                msg: "set_sl",
                strategy_group : strategy_group,
                coin_type:coin_type,
                exchange_no:exchange_no,
                exchange_name: exchange_name,
                value: value,
                transaction_id: transaction_id,
            };
            //alert(JSON.stringify(data))

            axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                alert(res.data.msg);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
            
        }

     }

     function saveHolding(strategy_group,coin_type ,exchange_no,exchange_name,transaction_id,input_id){
        let value = document.getElementById(input_id).value
        if (window.confirm('Do you want set value ?')) {
            let value = document.getElementById(input_id).value
            
            if (value == ""  ) {
                alert("Please check the value !");
                return false;
            }
            if (parseInt(value) <= 1) {
                alert("Please check the value !, The value must be greater than 1 minute.");
                return false;
            }
            
            const data = {
                bot_id: "f_bot",
                msg: "save_holding",
                strategy_group : strategy_group,
                coin_type:coin_type,
                exchange_no:exchange_no,
                exchange_name: exchange_name,
                value: value,
                transaction_id: transaction_id,
            };
            //alert(JSON.stringify(data))

            axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                alert(res.data.msg);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
            
        }

     }
     function stopHolding(strategy_group,coin_type ,exchange_no,exchange_name,transaction_id,input_id){
        let value = document.getElementById(input_id).value
        if (window.confirm('Do you want set value ?')) {
            let value = document.getElementById(input_id).value
            
            const data = {
                bot_id: "f_bot",
                msg: "stop_holding",
                strategy_group : strategy_group,
                coin_type:coin_type,
                exchange_no:exchange_no,
                exchange_name: exchange_name,
                value: '0',
                transaction_id: transaction_id,
            };
            //alert(JSON.stringify(data))

            axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                alert(res.data.msg);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
            
        }

     }

      function setInput_trigger_price(id,value){
        value = value.replace(".","")
        document.getElementById(id).value = value;
      }

      const save_trigger_price = (title,id) => {
        if (window.confirm(title)) {

            let temp_trigger_price = document.getElementById("buy_pair_trigger_price" + id).value

            //alert(temp_trigger_price);

            if (temp_trigger_price == ""  ) {
                alert("Please check trigger price !");
                return false;
            }
            
            const data = {
                bot_id: "f_bot",
                msg: "set_trigger_price_for_tradingview",
                buy_pair_trigger_price: temp_trigger_price,
                id: id,
            };
            //alert(JSON.stringify(data))

            axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                //alert(res.data.msg);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
            
        }
    };

    function setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,transaction_id,buy_pair_trigger_price,buy_pair_id,position){
        
        let time = dayjs().format("YYYY-MM-DD hh:mm:ss");
        let order_type ="market";

        if (msg == "manual_close"){
            order_type ="market";
        }else if( msg =="trigger"){
            order_type ="automarket";
        }
        else {
            order_type ="";
        }

        //alert(strategy_group);
        const this_data = {
                getmodel : "botScheduleCtl",
                gettogo : "trading_view",

                params : {
                    trading_type : "future",
                    id : id,
                    buy_pair_id : buy_pair_id,
                    strategy_group: strategy_group_no,
                    strategy_name: strategy_name,
                    exchange_name: exchanges_str + ":" + coin_type + fiat_type,
                    coin_type: coin_type + fiat_type,
                    time_frame: time_frame,
                    position: position,
                    price: "0",
                    buy_pair_trigger_price : buy_pair_trigger_price ,
                    operation_type: msg,
                    order_type: order_type,
                    transaction_id : transaction_id,
                    alarm_sent_time: String(new Date(time) / 1000)
                }
        };
        //alert(JSON.stringify(this_data))
        //alert(process.env.REACT_APP_TV_SERVER)
        //return false;

        axios
            .post(process.env.REACT_APP_TV_SERVER, this_data)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => console.log(err));
        

    }
    const forceControl = (title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,transaction_id,buy_pair_id,position) => {

        if (window.confirm(title)) {
            //let buy_pair_trigger_price = document.getElementById("buy_pair_trigger_price" + id).value
            //트리거 기능 삭제로 인해서 임시로 제로 설정
            let buy_pair_trigger_price = '0'

            if(msg == "trigger" ) {

                if(buy_pair_trigger_price == "" ) {
          
                    if (window.confirm("Do you want to abort trading date ?\n\n(You set space for trigger price)")) {
                        setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,transaction_id,buy_pair_trigger_price,buy_pair_id,position)
                    }

                }else {
                
                    setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,transaction_id,buy_pair_trigger_price,buy_pair_id,position)
                }


            }else {
                
                setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,transaction_id,buy_pair_trigger_price,buy_pair_id,position)
            }            
        }
    };

    function CPrice({grp, ticker}){
        let result = ""
        //alert(grp)
        //alert(ticker)

        //alert(JSON.stringify(balanceStatus));
        //alert(JSON.stringify(balanceStatus));
        balanceStatus.map((k, i) => {
            if (k.grp === grp && k.crypto === ticker) {
                result = parseFloat(k.c_price).toLocaleString("ko-KR", locale_opt)
            } 
        }) 
        return result
    }
    
    function ChangePrice({position,trading_price,grp, ticker}){
        let current_price = 0.0
        let result = ""
        //alert(grp)
        //alert(ticker)
        //alert(trading_price)

        //alert(JSON.stringify(balanceStatus));
        //alert(JSON.stringify(balanceStatus));
        balanceStatus.map((k, i) => {
            if (k.grp === grp && k.crypto === ticker) {
                current_price = parseFloat(k.c_price)
            } 
        }) 
        let temp_val = 0.0
        if (position == "SELL"){
            temp_val = parseFloat(trading_price) - current_price
            
        }else {

            temp_val = current_price - parseFloat(trading_price)
        }
        
        result = temp_val.toFixed(6).toString()
        result = parseFloat(result).toLocaleString("ko-KR", locale_opt)
        return result
    }
    
    

    return (
        <div className="first_div">
            <div className="second_div">
                <div>
                    <h5>Trading Strategy Status</h5>
                </div>
                &emsp;
                <table className=""  style={{ textAlign: "center" } } >
                    <thead style={{ background: "BLACK" }}>
                        <tr>
                            <th style={{ width: "3%" }}>STRATEGY</th>
                            <th style={{ width: "4%" }}>TICKER</th>
                            <th style={{ width: "3%" }}>COUNT</th>
                            <th style={{ width: "90%" }}>CONTENT</th>
                        </tr>
                    </thead>
                   
                    <tbody style={{ textAlign: "center" } } >
                    {
                        botStatus.map((bot,a) => (
                            Object.keys(bot).toString()=== exchangesSelect ? (
                                
                                // exchange
                                bot[Object.keys(bot)].map((strategy_group,b) => (

                                    // strategy group , mlc
                                    strategy_group[Object.keys(strategy_group)].map((coins,c) => (

                                        //contents -->  cointents[0]- btc , cointents[1] : {'bot_count': 4, 'bot_content': [{'id)
                                        Object.entries(coins).map((coin_contents,d) => (
                                        
                                            <tr style={{  textAlign: "center" , background: `${Object.keys(strategy_group).toString().toUpperCase() === "MLC" ? "#0070C0" : Object.keys(strategy_group).toString().toUpperCase() === "P2V" ? "#1E8062" : "#7030A0"}` }}>    
                                                <td style={{ width: "3%" }}>{Object.keys(strategy_group).toString().toUpperCase()}</td>
                                                <td style={{ width: "4%" }}>{coin_contents[0].toString().toUpperCase()}</td>
                                                <td style={{ width: "3%" }} >{coin_contents[1]['bot_count']}</td>
                                                <td style={{ width: "90%" }}>
                                                {
                                                    
                                                    Array.from({length: Number(coin_contents[1]['bot_content'].length)}, (v, i) => i).map((name_2, temp_index) => (
                                                        <>
                                                        <tr>
                                                            <td style={{ width: "5%" }}>BOT#{temp_index+1}</td>
                                                            <td style={{  width : "95%" ,textAlign: "center"}}>
                                                                {coin_contents[1]['bot_content'][temp_index]&&coin_contents[1]['bot_content'][temp_index] ? (
                                                                    <table style={{ width: "100%" }}>
                                                                    <tr>
                                                                    <td style={{ width: "1500px" ,textAlign: "center" }}>
                                                                        {
                                                                            coin_contents[1]['bot_content'][temp_index].sub_trading_data.map((sub_data,d) => (
                                                                               
                                                                                        <div
                                                                                        style={{ 
                                                                                            float : "left" ,
                                                                                            width: "300px" ,
                                                                                            display: "grid",
                                                                                            textAlign: "center",
                                                                                            border : "1px solid blue"
                                                                                        }}>
                                                                                            <span>{sub_data.strategy_name}<span style={{ color: "#FFC000" }}>_{sub_data.time_frame}</span></span>
                                                                                            <span>({sub_data.alarm_sent_time})</span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                Direction : {sub_data.position}
                                                                                            </span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                Leverage : {sub_data.leverage_size}
                                                                                            </span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                Odered Amount : {sub_data.crypto_qty} {sub_data.coin_type}
                                                                                            </span>
                                                                                            
                                                                                            <span>
                                                                                                {" "}
                                                                                                Filled Amount : {sub_data.crypto_qty} {sub_data.coin_type}
                                                                                            </span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                Filled Price : {sub_data.current_crypto_price} {sub_data.coin_type}
                                                                                            </span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                Fee : {sub_data.fee_paid}
                                                                                            </span>
                                                                                            <span style={{ color: "#FFC000" }}>
                                                                                                Order qty : {sub_data.order_perc + "%"}
                                                                                            </span>
                                                                                            <span style={{ color: "#FFC000" }}>
                                                                                                Leverage : {sub_data.leverage_size}
                                                                                            </span>
                                                                                            <span style={{ color: "#FFC000" }}>
                                                                                                Leverage qty : {sub_data.real_crypto_qty} {sub_data.coin_type}
                                                                                            </span>
                                                                                            <span style={{ color: "#FFC000" }}>
                                                                                                Current Price : <CPrice grp={sub_data.strategy_group_name} ticker={sub_data.coin_type} /> {sub_data.fiat_type}
                                                                                            </span>
                                                                                            <span style={{ color: "#FFC000" }}>
                                                                                                Change  : <ChangePrice sell_price={sub_data.current_crypto_price} grp={sub_data.strategy_group_name} ticker={sub_data.coin_type} /> {sub_data.fiat_type}
                                                                                            </span>
                                                                        
                                                                                            <div style={{ display: "flex" ,justifyContent :"center",margin: "0.1em 0em 0em 0em" }}>
                                                                                                {/* tp */}
                                                                                                <div style={{margin: "0em 0em 0px 0em" , width : "60%" }}>
                                                                                                Take Profit (%): <input type="text" style={{ margin: "0px 0px 0px 0px" , width: "90px" }} disabled="disabled" id={"tp_percent" + sub_data.id} defaultValue={sub_data.tp_percent}/>
                                                                                                </div>
                                                                                                {/*
                                                                                                <div style={{ width : "40%",justifyContent :"center" }}>
                                                                                                        <button style={{ background: "green", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={(e) => saveTp(sub_data.strategy_group,sub_data.coin_type,sub_data.exchange_no, sub_data.exchange_name,sub_data.transaction_id,"tp_percent" + sub_data.id)} >Save</button>
                                                                                                </div>
                                                                                                */}
                                                                                            </div>
                                                                                            <div style={{ display: "flex" ,justifyContent :"center",margin: "0.1em 0em 0em 0em" }}>
                                                                                                {/* sl */}
                                                                                                <div style={{margin: "0em 0em 0px 0em" , width : "60%" }}>
                                                                                                Stop Loss (%): <input type="text" style={{ margin: "0px 0px 0px 0px" , width: "90px" }} disabled="disabled" id={"sl_percent" + sub_data.id} defaultValue={sub_data.sl_percent}/>
                                                                                                </div>
                                                                                                 {/*
                                                                                                <div style={{ width : "40%",justifyContent :"center" }}>
                                                                                                        <button style={{ background: "green", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={(e) => saveSl(sub_data.strategy_group, sub_data.coin_type,sub_data.exchange_no,sub_data.exchange_name,sub_data.transaction_id,"sl_percent" + sub_data.id)} >Save</button>
                                                                                                </div>
                                                                                                */}
                                                                                            </div>
                                                                                            <div style={{ justifyContent :"center",margin: "1em 0em 0em 0em" }}>
                                                                                                {/* holding */}
                                                                                                <div style={{margin: "0em 0em 0px 0em" , width : "100%" }}>
                                                                                                    Holding Time : <input type="text" style={{ margin: "0px 0px 0px 0px" , width: "90px" }} disabled="disabled"  id={"holding" + sub_data.id} defaultValue={sub_data.holding}/>
                                                                                                </div>
                                                                                                <div style={{margin: "0em 0em 0px 0em" , width : "100%" }}>
                                                                                                    Remaining Time (Force Close) : <input type="text" style={{ margin: "0px 0px 0px 0px" , width: "90px" }}   id={"diff_min" + sub_data.id} defaultValue={sub_data.diff_min}/>
                                                                                                </div>
                                                                                                <div style={{ display: "flex" ,justifyContent :"center",margin: "0.5em 0em 0em 0em" }}>
                                                                                                    <div style={{ width : "30%",justifyContent :"center" }}>
                                                                                                            <button style={{ background: "gray", color: "white", width: "90px", margin: "auto" }}
                                                                                                            onClick={(e) => stopHolding(sub_data.strategy_group, sub_data.coin_type,sub_data.exchange_no,sub_data.exchange_name,sub_data.transaction_id,"holding" + sub_data.id)} >Stop</button>
                                                                                                    </div>
                                                                                                    <div style={{ width : "30%",justifyContent :"center" }}>
                                                                                                            <button style={{ background: "green", color: "white", width: "90px", margin: "auto" }}
                                                                                                            onClick={(e) => saveHolding(sub_data.strategy_group, sub_data.coin_type,sub_data.exchange_no,sub_data.exchange_name,sub_data.transaction_id,"diff_min" + sub_data.id)} >Save</button>
                                                                                                    </div>

                                                                                                </div>
                                                                                                
                                                                                            </div>
                                                                                            
                                                                                            <div style={{ display: "flex" ,margin: "0em 0em 2em 0em" }}>
                                                                                               
                                                                                                {/* trigger price 
                                                                                                <div style={{margin: "2.5em 0px 0px 0em" , width : "60%" }}>
                                                                                                Trigger Price : <input type="text" style={{ margin: "0px 0px 0px 0px" , width: "50%" }}  id={"buy_pair_trigger_price" + sub_data.id} defaultValue={sub_data.buy_pair_trigger_price} disabled={editButtons[sub_data.id] === "edit" ? "" : "disabled"} onChange={(e) => setInput_trigger_price(e.target.id, e.target.value)}/>
                                                                                                </div>
                                                                                                <div hidden={editButtons[sub_data.id] === "edit" ? true : false}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                                                    <div style={{ width : "100%" }}>
                                                                                                        <button style={{ background: "green", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={(e) => handleEdit(sub_data.strategy_group_no,sub_data.id,"edit")} >Edit</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                */}
                                                                                                {/* save button 
                                                                                                {/*
                                                                                                <div hidden={editButtons[sub_data.id] === "edit" ? false : true}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                                                    <div style={{ width : "100%" }}>
                                                                                                        <button style={{ background: "orange", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={(e) => save_trigger_price("Do you want to set trigger price ?" ,sub_data.id) } >Save</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                */}
                                                                                                <div hidden={editButtons[sub_data.id] === "edit" ? false : true}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                                                    <div style={{ width : "100%" }}>
                                                                                                        <button style={{ background: "orange", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={() => forceControl("Save Trigger Price?", "trigger", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name,sub_data.coin_type , sub_data.time_frame, sub_data.fiat_type,sub_data.id,sub_data.transaction_id,sub_data.buy_pair_id,sub_data.position)}>Save</button>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div style={{ display: "flex" }}>
                                                                                                <button
                                                                                                    style={{ background: "orange", color: "white", width: "110px", height: "30px", margin: "auto" }}
                                                                                                    onClick={() => forceControl("Close position?", "manual_close", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name, sub_data.coin_type,sub_data.time_frame, sub_data.fiat_type , sub_data.id,sub_data.transaction_id, sub_data.buy_pair_id,sub_data.position)}>                                                                               
                                                                                                    Close Position
                                                                                                </button>
                                                                                               {/* 
                                                                                                <button
                                                                                                    style={{ background: "red", color: "white", width: "110px", margin: "auto" }}
                                                                                                    onClick={() => forceControl("Terminate position?", "terminate", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name,sub_data.coin_type , sub_data.time_frame, sub_data.fiat_type, sub_data.id, sub_data.buy_pair_id)}>
                                                                                                    Terminate
                                                                                                </button>
                                                                                                */}
                                                                                            </div>
                                                                                        </div>
                                                                            ) )
                                                                        
                                                                        }
                                                                    </td>
                                                                    </tr>
                                                                    </table>
                                                                ) : (
                                                                    <table className="table_none_border">
                                                                    <tr>
                                                                    <td className="table_none_border">
                                                                        <span>x</span>
                                                                     </td>
                                                                     </tr>
                                                                     </table>

                                                            )
                                                            }
                                                        </td>
                                                        </tr>
                                                        </>
                                                    ))
                                                }
                                                </td>
                                            </tr>
                                        ))//Object.entries(coins).map((coin_contents,d)
                                    )) //strategy_group[Object.keys(strategy_group)].map((coins,c
                                ))
                            ) : null
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BotStatus_new;


