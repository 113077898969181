import React, { useState, useEffect } from "react";

import axios from "axios";
import dayjs from "dayjs";


function InputAssignAssetFiat({ exchange_no, wallet ,balanceStatus,closeUiOfAssignFiat }) {

      
console.log("wallet : " + wallet)
// 버튼 스타일
const btnStyle = {
    margin: "0 5px",
    padding: "10px",
    cursor: "pointer",
    border: "1px solid #ccc",
    borderRadius: "3px",
    backgroundColor: "#f8f8f8"
};

  
  const [fiatfree, setFiatfree] = useState("0.0");
  const [coinList, setCoinList] = useState([]);
  const [assignValues, setAssignValues] = useState({});
  const [assignPercent, setAssignPercent] = useState({});


  function closeAsset(){
    closeUiOfAssignFiat();
  }

  function updateAsset() {

    const info_total = { bot_id: "p_bot", msg: "update_fiat_assignment",exchange_no : exchange_no,  wallet : wallet, coinList: coinList, assignValues: assignValues ,assignPercent : assignPercent};
 
    axios
        .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, info_total)
        .then((res) => {
            if (res.data.result == "ok") {
                alert(res.data.msg);
                closeUiOfAssignFiat();
            }else {
                alert("Error!\n\nThere is a system error !");
                closeUiOfAssignFiat();
            }
        })
        .catch((err) => {
            //console.log(err);
        });
    
  }


  function calAssignValue(assing_ratio, current_qty, target_form_name, percent_form_name) {
    if (assing_ratio !== "" && current_qty !== "") {
      let temp_assing_ratio = parseFloat(assing_ratio.replace(",", "."));
      let temp_current_qty = parseFloat(current_qty);


      let temp_update_qty = temp_current_qty * (temp_assing_ratio / 100);

      setAssignValues((prevValues) => ({
        ...prevValues,
        [target_form_name]: temp_update_qty.toFixed(4),
      }));
    }

    setAssignPercent((prevValues) => ({
        ...prevValues,
        [percent_form_name]: assing_ratio,
      }));

  }

  function addValue(percent_form_name, current_qty, target_form_name) {

    console.log("percent_form_name : " + percent_form_name)
    console.log("assignPercent[percent_form_name] : " + assignPercent[percent_form_name])

    let temp_percent =  parseFloat(assignPercent[percent_form_name] )+ 0.1
    setAssignPercent((prevValues) => ({
        ...prevValues,
        [percent_form_name]: temp_percent.toFixed(1),
      }));

    

    console.log("current_qty : " + current_qty)
    console.log("temp_percent : " + temp_percent)
    console.log("target_form_name : " + target_form_name)

    let temp_qty =  parseFloat(current_qty) * temp_percent / 100
    console.log("temp_qty : " + temp_qty)

    setAssignValues((prevValues) => ({
        ...prevValues,
        [target_form_name]: temp_qty.toFixed(4),
      }));

  }

  function delValue(percent_form_name, current_qty, target_form_name) {

    console.log("percent_form_name : " + percent_form_name)
    console.log("assignPercent[percent_form_name] : " + assignPercent[percent_form_name])

    let temp_percent =  parseFloat(assignPercent[percent_form_name] ) - 0.1
    setAssignPercent((prevValues) => ({
        ...prevValues,
        [percent_form_name]: temp_percent,
      }));

    

    console.log("current_qty : " + current_qty)
    console.log("temp_percent : " + temp_percent)
    console.log("target_form_name : " + target_form_name)

    let temp_qty =  parseFloat(current_qty) * temp_percent / 100
    console.log("temp_qty : " + temp_qty)

    setAssignValues((prevValues) => ({
        ...prevValues,
        [target_form_name]: temp_qty,
      }));

  }
    
useEffect(() => {

    console.log("balanceStatus : " + JSON.stringify(balanceStatus));

    let temp_assignPercent = {};
    let temp_assignValues = {};
    let tmpe_coinList = [];
    let tmpe_fiat_free = 0.0;

    balanceStatus.forEach((name) => {
       
        if (name.grp.toString().toUpperCase() === wallet.toString().toUpperCase()) {

            if (name.crypto.toString().toUpperCase() == "KRW" || "USDT") {
                setFiatfree(name.free)
                tmpe_fiat_free =  parseFloat(name.free) || 0;
            }

        }
    });

    
    balanceStatus.forEach((name) => {
       
      if (name.grp.toString().toUpperCase() === wallet.toString().toUpperCase()) {
         
            // 안전한 숫자 변환
            const fiat_assignment = parseFloat(name.fiat_assignment) || 0;
            const free = parseFloat(tmpe_fiat_free) || 0;

            // 퍼센트 값 저장
            temp_assignPercent["fiat_assignment_update_" + name.crypto] = fiat_assignment;
            // 계산된 값 저장 (소수점 첫 번째 자리까지 반올림)
            temp_assignValues["fiat_assignment_" + name.crypto] = Math.round(free * (fiat_assignment / 100) * 10) / 10;
            // 퍼센트 값 저장
            tmpe_coinList.push(name.crypto);

      }
    });


    console.log("temp_assignPercent : " + JSON.stringify(temp_assignPercent));
    console.log("temp_assignValues : " + JSON.stringify(temp_assignValues));
    console.log("tmpe_coinList : " + JSON.stringify(tmpe_coinList));

    setAssignPercent(temp_assignPercent)
    setAssignValues(temp_assignValues)
    setCoinList(tmpe_coinList)

}, []);


  return (
    <div>
      <div style={{ width: "50%" }}>
        <h5>Fiat Asset Assignment</h5>
        <table>
          <thead style={{ background: "#6CA0EC" }}>
            <tr>
              <th>Coin Name</th>
              <th>Current Free</th>
              <th>Assignment (Amount)</th>
              <th>Assignment (Percent)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {balanceStatus &&
              balanceStatus.map((name, i) =>
              
                name.grp.toString().toUpperCase() === wallet.toString().toUpperCase() ? (
                  <tr key={i} style={{ background: i % 2 === 0 ? "#142440" : "#233E6F" }}>
                    <td style={{ width: "20%" }}>{name.crypto}</td>
                    <td style={{ width: "20%" }}>
                      {parseFloat(fiatfree).toLocaleString("ko-KR", { maximumFractionDigits: 8 })}
                    </td>
                    {name.crypto !== "KRW" ? (
                      <>
                        <td style={{ width: "20%" }}>
                          <input
                            style={{ width: "100%" }}
                            type="number"
                            id={"fiat_assignment_" + name.crypto}
                            name={"fiat_assignment_" + name.crypto}
                            value={assignValues["fiat_assignment_" + name.crypto] || ""}
                            readOnly
                          />
                        </td>
                      </>
                    ) : (
                      <td style={{ width: "20%" }}>X</td>
                    )}
                    {name.crypto !== "KRW" ? (
                      <>
                        <td style={{ width: "20%" }}>
                          <input
                            onChange={(e) => calAssignValue(e.target.value, name.free, "fiat_assignment_" + name.crypto,"fiat_assignment_update_" + name.crypto)}
                            style={{ width: "100%" }}
                            type="number"
                            id={"fiat_assignment_update_" + name.crypto}
                            name={"fiat_assignment_update_" + name.crypto}
                            value={assignPercent["fiat_assignment_update_" + name.crypto] || ""}
                          />
                        </td>
                      </>
                    ) : (
                      <td style={{ width: "20%" }}>X</td>
                    )}
                    {name.crypto !== "KRW" ? (
                        <>
                          <td style={{ width: "20%" }}>
                            <button style={btnStyle} onClick={(e) => addValue("fiat_assignment_update_" + name.crypto,name.free,"fiat_assignment_" + name.crypto) }>+</button>

                            <button style={btnStyle} onClick={(e) => delValue("fiat_assignment_update_" + name.crypto,name.free,"fiat_assignment_" + name.crypto) }>-</button>
                          </td>
                        </>
                      ) : (
                        <td style={{ width: "20%" }}>X</td>
                      )}
                  </tr>
                ) : null
              )}
          </tbody>
        </table>
        <button className="btn btn-success btn-sm" style={{backgroundColor : "blue" , margin: "10px 0px 0px 0px" }} onClick={updateAsset}>
          Update
        </button>
        <button className="btn btn-success btn-sm" style={{backgroundColor : "blue" , margin: "10px 0px 0px 10px" }} onClick={closeAsset}>
          Close
        </button>
      </div>
    </div>
  );
}

export default InputAssignAssetFiat;
