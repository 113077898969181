import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import HeaderNew from "../../headerFooterSide/HeaderNew";
import { DateTime_Div } from "../../common/Commons";
import TradingView from "../TradingView/TradingView";
import TradingLog from "../TradingView/TradingLog";
import ModalChart from "../../common/ModalChart";
import { DevideSelector } from "./DevideSelector";
import { WalletSelector } from "./WalletSelector";
import Switch from "react-switch";

function BackTesting_Simuation({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [datalogs, setDatalogs] = useState([]);
    const [hide,set_hide] = useState(false)


    const [strategy_array, set_strategy_array] = useState([]);
    const [current_strategy, set_current_strategy] = useState("");

    const [signal_type_array, set_signal_type_array] = useState([]);
    const [current_signal_type, set_current_signal_type] = useState("");

    const [strategy_entry_type_array, set_strategy_entry_type_array] = useState([]);
    const [current_strategy_entry_type, set_current_strategy_entry_type] = useState("");

    const [time_frame_array, set_time_frame_array] = useState([]);
    const [current_time_frame, set_current_time_frame] = useState("");

    const [exchange_array, set_exchange_array] = useState([]);
    const [current_exchange, set_current_exchange] = useState("");

    const [coin_array, set_coin_array] = useState([]);
    const [current_coin, set_current_coin] = useState("");

    const [current_entry_direction, set_current_entry_direction] = useState("");


    const [strategy_id, set_strategy_id] = useState("");
    const [strategy_name, set_strategy] = useState("");
    const [exchange_name, set_exchange] = useState("");
    const [coin_type, set_coin] = useState("");
    const [time_frame, set_time_frame] = useState("");
    const [entry_direction, set_entry_direction] = useState("");
    const [parameter_json, set_parameter_json] = useState({});
    const [sl_status, set_sl_status] = useState({});
    const [ai_sl_tp_status, set_ai_sl_tp_status] = useState({});
    
    const [direction_status, set_direction_status] = useState({});
    const [start_trade_time, set_start_trade_time] = useState("");


    const [force_short_stop_loss_atype_enable, set_force_short_stop_loss_atype_enable] = useState(false);
    const [force_short_stop_loss_atype_value, set_force_short_stop_loss_atype_value] = useState("");

    const [force_long_stop_loss_atype_enable, set_force_long_stop_loss_atype_enable] = useState(false);
    const [force_long_stop_loss_atype_value, set_force_long_stop_loss_atype_value] = useState("");


    const [force_short_stop_loss_btype_enable, set_force_short_stop_loss_btype_enable] = useState(false);
    const [force_short_stop_loss_btype_value, set_force_short_stop_loss_btype_value] = useState("");

    const [force_long_stop_loss_btype_enable, set_force_long_stop_loss_btype_enable] = useState(false);
    const [force_long_stop_loss_btype_value, set_force_long_stop_loss_btype_value] = useState("");



    const [result_file_path, set_result_file_path] = useState("");
    const [operation, set_operation] = useState("");



    const handle_strategy_name = (e) => {
        set_current_strategy(e.target.value);
      };
    const handle_signal_type = (e) => {
        set_current_signal_type(e.target.value);
    };
    const handle_strategy_entry_type = (e) => {
        set_current_strategy_entry_type(e.target.value);
    };
  
    const handle_time_frame = (e) => {
        set_current_time_frame(e.target.value);
    };
    
    const handle_exchange = (e) => {
    set_current_exchange(e.target.value);
    };

    const handle_coin = (e) => {
    set_current_coin(e.target.value);
    };
    
    const handle_entry_direction = (e) => {
    set_current_entry_direction(e.target.value);
    };


    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    // modal
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [modalOpen, setModalOpen] = useState(false);
    const openModal = (name) => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    /////////////////////////////////////////////////////////////////////////////////////////////////////////



    const [switch_checked, set_switch_checked] = useState(false);
    function onSwitchChage(checked){
        set_switch_checked(checked)
    }


    function delete_live(strategy_id){
        
        if (window.confirm('Do you want to apply ?')) {
    
    
        //alert(short_order_html)
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
            strategy_id : strategy_id,
        };

        //alert(JSON.stringify(data))
        var turl = "delete_live"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
            else {
                alert(res.data.error);
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

    }

    const dataItemToKeyValues = (item) => {

       
        const entries = Object.entries(item);
        const listItems = entries.map(([key, value]) => (

            key!=="exchange_name" && key!=="coin_type" && key!=="timeframe" && key!=="entrydirection" && (
                <>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "white" }}>{repace_item(key)}:</label>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "#FFC000" }}>{value}</label>
                </>
            )
 
        ));
        return (
                <div style={{width:"100%" ,display:"flex",flexWrap:"wrap" ,gap:"10px",margin: "0px 0px 0px 0px"}}>
                    {listItems}
                </div>
            )
    };


    const dataItemToKeyValues_AI_SL_TP = (item) => {

       
        const entries = Object.entries(item);
        const listItems = entries.map(([key, value]) => (

            key!=="exchange_name" && key!=="coin_type" && key!=="timeframe" && key!=="entrydirection" && (
                <>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "white" }}>{repace_item(key)}:</label>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "#FFC000" }}>{value}</label>
                </>
            )
 
        ));
        return (
                <div style={{width:"100%" ,display:"flex",flexWrap:"wrap" ,gap:"10px",margin: "0px 0px 0px 0px"}}>
                    {listItems}
                </div>
            )
    };
    

    const dataItemToKeyValues_SL = (item) => {

       
        const entries = Object.entries(item);
        const listItems = entries.map(([key, value]) => (

            key!=="exchange_name" && key!=="coin_type" && key!=="timeframe" && key!=="entrydirection" && (
                <>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "white" }}>{repace_item(key)}:</label>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "#FFC000" }}>{value}</label>
                </>
            )
 
        ));
        return (
                <div style={{width:"100%" ,display:"flex",flexWrap:"wrap" ,gap:"10px",margin: "0px 0px 0px 0px"}}>
                    {listItems}
                </div>
            )
    };
    
    const dataItemToKeyValues_Direction= (item) => {

       
        const entries = Object.entries(item);
        const listItems = entries.map(([key, value]) => (

            key!=="exchange_name" && key!=="coin_type" && key!=="timeframe" && key!=="entrydirection" && (
                <>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "white" }}>{repace_item(key)}:</label>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "#FFC000" }}>{value}</label>
                </>
            )
 
        ));
        return (
                <div style={{width:"100%" ,display:"flex",flexWrap:"wrap" ,gap:"10px",margin: "0px 0px 0px 0px"}}>
                    {listItems}
                </div>
            )
    };

    function repace_item(iputstr){

        iputstr = iputstr.replace("bollenger_length","bollinger_length")
        iputstr = iputstr.replace("bollenger_dev","bollinger_dev")
        iputstr = iputstr.replace("mini_","min_")
        iputstr = iputstr.replace("shortlimitcondition","short_limit_condition")
        iputstr = iputstr.replace("longlimitcondition","long_limit_condition")
        iputstr = iputstr.replace("st_periodk","st_period_k")
        iputstr = iputstr.replace("st_smoothk","st_smooth_k")
        iputstr = iputstr.replace("st_periodd","st_period_d")

        
        iputstr = iputstr.replace("fixed_percentage","Fixed Percentage")
        iputstr = iputstr.replace("moving_average","Moving Average")
        iputstr = iputstr.replace("volatility_based","Volatility")
        iputstr = iputstr.replace("atr_based","ATR")
        iputstr = iputstr.replace("support_level","Support Level")
        iputstr = iputstr.replace("resistance_level","Resistance Level")
        iputstr = iputstr.replace("volume_based_spike","Volume Spike")


        iputstr = iputstr.replace("direction","Direction")
        iputstr = iputstr.replace("support_level","Support Level")
        iputstr = iputstr.replace("resistance_level","Resistance Level ")



        iputstr = iputstr.replace("sl","SL")
        iputstr = iputstr.replace("tp","TP")

        iputstr = iputstr.replace("last_position","Last Position")
        iputstr = iputstr.replace("last_price","Last Price")
        
        return iputstr
    }


    function HideView(){
        set_hide(false)
    }
    function DisplayView(){
        set_hide(true)
    }


    


    
  function chage_status_to_sentinel(checked,strategy_id){

    if (window.confirm('Do you want to apply ?')) {

        var to_sentinel = ""
        if (checked ){
            to_sentinel = "false"
        }else{
            to_sentinel = "true"
        }
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          to_sentinel : to_sentinel
        };
        var turl = "chage_status_to_sentinel"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

  

  function chage_status_individual(checked,strategy_id){

    if (window.confirm('Do you want to apply ?')) {
    

        var sl_tp_individual_last = ""
        if (checked ){
            sl_tp_individual_last = "individual"
        }else{
            sl_tp_individual_last = "last"
        }
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          sl_tp_individual_last : sl_tp_individual_last
        };
        var turl = "chage_status_individual"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }


  function chage_status_force_sl_tp(checked,strategy_id){

    if (window.confirm('Do you want to apply ?')) {
    

        var force_sl_tp = ""
        if (checked ){
            force_sl_tp = "false"
        }else{
            force_sl_tp = "true"
        }
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          force_sl_tp : force_sl_tp
        };
        var turl = "chage_status_force_sl_tp"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }


  function chage_status_devide_type(checked,strategy_id){

    if (window.confirm('Do you want to apply ?')) {

        var devide_type = ""
        if (checked ){
            devide_type = "all"
        }else{
            devide_type = "partial"
        }
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          devide_type : devide_type
        };
        var turl = "chage_status_devide_type"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }
  
  function chage_status_basic_sl(checked,strategy_id){

    if (window.confirm('Do you want to apply ?')) {

        var basic_sl = ""
        if (checked ){
            basic_sl = "true"
        }else{
            basic_sl = "false"
        }
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          basic_sl : basic_sl
        };
        var turl = "chage_status_basic_sl"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }
  
  function chage_status_ai_sl(checked,strategy_id){

    if (window.confirm('Do you want to apply ?')) {

        var ai_sl = ""
        if (checked ){
            ai_sl = "true"
        }else{
            ai_sl = "false"
        }
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          ai_sl : ai_sl
        };
        var turl = "chage_status_ai_sl"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

  function chage_status_ai_tp(checked,strategy_id){

    if (window.confirm('Do you want to apply ?')) {

        var ai_tp = ""
        if (checked ){
            ai_tp = "true"
        }else{
            ai_tp = "false"
        }
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          ai_tp : ai_tp
        };
        var turl = "chage_status_ai_tp"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

  function UpdateLimt(strategy_id){

    if (window.confirm('Do you want to apply ?')) {

        var force_short_stop_loss_atype_value_html= strategy_id + "_text_force_short_stop_loss_atype"
        var force_long_stop_loss_atype_value_html= strategy_id + "_text_force_long_stop_loss_atype"
    
        var force_short_stop_loss_btype_value_html= strategy_id + "_text_force_short_stop_loss_btype"
        var force_long_stop_loss_btype_value_html= strategy_id + "_text_force_long_stop_loss_btype"
        var force_stop_loss_time_btype_value_html= strategy_id + "_text_force_stop_loss_time_btype"
        
        
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          force_short_stop_loss_atype : document.getElementById(force_short_stop_loss_atype_value_html).value ,
          force_long_stop_loss_atype : document.getElementById(force_long_stop_loss_atype_value_html).value ,
    
          force_short_stop_loss_btype : document.getElementById(force_short_stop_loss_btype_value_html).value ,
          force_long_stop_loss_btype : document.getElementById(force_long_stop_loss_btype_value_html).value ,
          force_stop_loss_time_btype : document.getElementById(force_stop_loss_time_btype_value_html).value ,

          
        };
        var turl = "update_live_trading_limit"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  } 

  function Update_consecutive_number(strategy_id){

    if (window.confirm('Do you want to apply ?')) {


        var consecutive_number_html= strategy_id + "_text_consecutive_number_html"
       
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          consecutive_number : document.getElementById(consecutive_number_html).value ,
    
        };
        var turl = "update_consecutive_number"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

  function Update_short_order(strategy_id){

    if (window.confirm('Do you want to apply ?')) {


        var short_order_html= strategy_id + "_text_short_order_html"
       
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          short_order : document.getElementById(short_order_html).value ,
    
        };
        var turl = "update_short_order"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }
  function Update_long_order(strategy_id){

    if (window.confirm('Do you want to apply ?')) {


        var long_order_html= strategy_id + "_text_long_order_html"
       
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          long_ordder : document.getElementById(long_order_html).value ,
    
        };
        var turl = "update_long_order"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

  function Update_SL_TP(strategy_id){

    if (window.confirm('Do you want to apply ?')) {


        var short_sl_html= strategy_id + "_text_short_sl_html"
        var long_sl_html= strategy_id + "_text_long_sl_html"
    
        var short_tp_html= strategy_id + "_text_short_tp_html"
        var long_tp_html= strategy_id + "_text_long_tp_html"

        
    
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          short_sl : document.getElementById(short_sl_html).value ,
          long_sl : document.getElementById(long_sl_html).value ,
          short_tp : document.getElementById(short_tp_html).value ,
          long_tp : document.getElementById(long_tp_html).value ,
    
        };
        var turl = "update_live_trading_sl_tp"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

  

  function Stop_Trading(checked,id){

    var trading_status = ""
    if (checked){
        trading_status = "stop"
    }else {
        trading_status = "running"

    }

    if (window.confirm('Do you want to apply ?')) {
        //console.log("get_candle_realtime ! ")
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : id,
          trading_status: trading_status,
        };

        var turl = "update_trading_status"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

  function update_live_trading_opti(checked,id){

    var operation = ""
    if (checked){
        operation = "stop"
    }else {
        operation = "running"

    }

    if (window.confirm('Do you want to apply ?')) {
        //console.log("get_candle_realtime ! ")
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : id,
          operation: operation,
        };

        var turl = "update_live_trading_opti"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }


    function get_backtesting_resource() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)
        const data = {
            strategy_name: "strategy_name",
            coin_name: "coin_name",
            entry_type: "entry_type",
        };
        axios
            .post(`${python_server_url}/get_backtesting_resource_live`, data)
            .then((res) => {
                //console.log("res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {

                    
                    set_strategy_array(res.data.result.strategy_array);
                    
                    set_exchange_array(res.data.result.exchange_array);
                    set_coin_array(res.data.result.coin_array);


                    set_signal_type_array(res.data.result.signal_type_array);
                    set_strategy_entry_type_array(res.data.result.strategy_entry_type_array);
                    set_time_frame_array(res.data.result.time_frame_array);
                    
                    //console.log("get_backtesting_resource_live , res.data.result.strategy_array ==> " + res.data.result.strategy_array)
                } else {
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
                setDatalogs([]);
            });
    }


    function get_backtesting_status_all() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)


        const data = {
            strategy_name: document.getElementById("strategy_name").value ,
            signal_type: document.getElementById("signal_type").value ,
            strategy_entry_type: document.getElementById("strategy_entry_type").value ,
            time_frame: document.getElementById("time_frame").value ,
            coin_type: document.getElementById("coin").value ,
            exchange_name: document.getElementById("exchange").value ,

        };
        axios
            //.post(`${python_server_url}/get_backtesting_status_all_by_strategy_live`, data)
            .post(`${python_server_url}/get_backtesting_status_all_by_strategy_live_v2`, data)
            .then((res) => {
                //console.log("res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {
                    setDatalogs(res.data.result);

                    //console.log("datalogs ==> " + JSON.stringify(datalogs))
                } else {
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
                setDatalogs([]);
            });
    }

    function get_backtesting_status_detail(strategy_id) {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)
        const data = {
            strategy_id: strategy_id,
        };
        axios
            .post(`${python_server_url}/get_backtesting_status_detail_live`, data)
            .then((res) => {
                //console.log("get_backtesting_status_detail , res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {
                    
                    set_strategy_id(res.data.result.id)
                    set_strategy(res.data.result.strategy_name);
                    set_exchange(res.data.result.exchange_name);
                    set_coin(res.data.result.coin_type);
                    set_time_frame(res.data.result.time_frame);
                    set_entry_direction(res.data.result.entry_direction);

                    //set_result_file_path(res.data.result.result_file_path_to_sentinel);
                    set_result_file_path(res.data.result.result_file_path);

                    set_start_trade_time(res.data.result.start_trade_time);
                    
                    var parameter_json = res.data.result.parameter_json
                    if (parameter_json != "" && parameter_json != undefined){
                        set_parameter_json(JSON.parse( parameter_json.replace(/'/g, '"')))
                    }

                    var sl_status = res.data.result.sl_status
                    if (sl_status != "" && sl_status != undefined){
                        set_sl_status(JSON.parse( sl_status.replace(/'/g, '"')))
                    }
                    var ai_sl_tp_status = res.data.result.ai_sl_tp_status
                    if (ai_sl_tp_status != "" && ai_sl_tp_status != undefined){
                        set_ai_sl_tp_status(JSON.parse( ai_sl_tp_status.replace(/'/g, '"')))
                    }

                    var direction_status = res.data.result.direction_status
                    if (direction_status != "" && direction_status != undefined){
                        set_direction_status(JSON.parse( direction_status.replace(/'/g, '"')))
                    }
                    

                    
                    
                    //console.log("get_backtesting_resource_live , res.data.result.parameter_json ==> " + parameter_json)
                    set_operation(res.data.result.operation)

                    //console.log("get_backtesting_status_detail_live , res.data.result.result_file_path ==> " + res.data.result.result_file_path)
                    setModalOpen(true);
                } else {
                   
                }
            })
            .catch((err) => {
                console.log(err);
                setDatalogs([]);
            });
    }
    function StopStartegy(strategy_id){

        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)
        const data = {
            strategy_id: strategy_id,
        };
        axios
            .post(`${python_server_url}/set_backtesting_stop`, data)
            .then((res) => {
                //console.log("set_backtesting_stop , res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.msg == "ok") {
                   alert("Operation is updated !")
                   get_backtesting_status_all()
                } else {

                   alert(res.data.error)
                   
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

  function Reset_LiveData(strategy_id){


    if (window.confirm('Do you want to apply ?')) {
        //console.log("get_candle_realtime ! ")
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id
        };

        var turl = "reset_live_trading"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
            if (res.data.status == "fail") {
                alert("System error!")
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

    


    function Refresh(){
        get_backtesting_status_all()
    }
    function ViewChart(strategy_id){
        console.log("ViewChart, strategy_id  ==>" + strategy_id )
        get_backtesting_status_detail(strategy_id)
        
    }

    useEffect(() => {
        setTile("Live Trading")
        get_backtesting_resource()
    }, []);


    useEffect(() => {
        get_backtesting_status_all()
    },  [current_strategy,current_signal_type,current_strategy_entry_type,current_time_frame ,current_exchange,current_coin,current_entry_direction]);

    useEffect(() => {
        const intervalId = setInterval(() => {
        //window.location.reload()
        get_backtesting_status_all()
        }, 1000*10);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <br></br>
            <div>
                <div style={{margin: "1px 0px 0px 0px"}}>
                    <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                        <div style={{margin: "0px 0px 0px 0px" ,width:"95%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> Display Filters</label> 
                        </div>

                        <div style={{margin: "10px 0px 0px 0px" ,width:"95%" ,background:"#1c1b2b"}}>
                            
                            <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>Strategy</label>
                            <select onChange={handle_strategy_name} id="strategy_name" style={{ margin: "0px 10px 0px 10px", fontSize: "14px" }}
                                value={current_strategy}>
                                {
                                    strategy_array && strategy_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>

                            <label htmlFor="signal_type" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>Signal Type</label>
                            <select onChange={handle_signal_type} id="signal_type" style={{ margin: "0px 10px 0px 10px", fontSize: "14px" }}
                                value={current_signal_type}>
                                {
                                    signal_type_array && signal_type_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>

                            <label htmlFor="strategy_entry_type" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>Entry Type</label>
                            <select onChange={handle_strategy_entry_type} id="strategy_entry_type" style={{ margin: "0px 10px 0px 10px", fontSize: "14px" }}
                                value={current_strategy_entry_type}>
                                {
                                    strategy_entry_type_array && strategy_entry_type_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>

                            

                            <label htmlFor="exchange" style={{ margin: "0px 0px 0px 20px", fontSize: "14px", color: "white" }}>Exchage</label>
                            <select onChange={handle_exchange} id="exchange" style={{ margin: "0px 10px 0px 10px", fontSize: "14px", width: "100px" }}
                                value={current_exchange}>
                                {exchange_array && exchange_array.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <label htmlFor="coin" style={{ margin: "0px 0px 0px 20px", fontSize: "14px", color: "white" }}>Coin</label>
                            <select onChange={handle_coin} id="coin" style={{ margin: "0px 10px 0px 10px", fontSize: "14px", width: "100px" }}
                                value={current_coin}>
                                {coin_array && coin_array.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <label htmlFor="time_frame" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>Time Frame</label>
                            <select onChange={handle_time_frame} id="time_frame" style={{ margin: "0px 10px 0px 10px", fontSize: "14px" }}
                                value={current_time_frame}>
                                {
                                    time_frame_array && time_frame_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>
                        </div>

                    </div>
                    {/* 
                    <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                        <div style={{margin: "0px 0px 0px 0px" ,width:"95%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> Backtesting Period</label> 
                        </div>

                        <div style={{margin: "10px 0px 0px 0px" ,width:"95%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px",color: "white" }}>Period</label> 
                           
                            <label htmlFor="from_date" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>From</label> 
                            <input type="text" disabled id="from_date" name="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", width: "100px"}} value=""/>

                            <label htmlFor="" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>~</label>

                            <label htmlFor="to_date" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>To</label> 
                            <input type="text" disabled id="to_date" name="to_date" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", width: "100px"}} value=""/>
                        </div>

                    </div>
                    */}

                    {
                        hide === false && (

                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                            <div style={{margin: "0px 0px 0px 0px" ,width:"100%"}}>
                                <button className="btn btn-success btn-sm" style={{margin: "0px 0px 0px 30px" }} onClick={(e) => Refresh()}>
                                    Refresh List
                                </button>
                            </div>

                            <div style={{width:"100%" ,flexWrap:"wrap" ,gap:"10px",margin: "0px 0px 0px 0px"}}>

                                {/* /////////////////////////////////////////////////////// strategy /////////////////////////////////////////////////////// */}
                                { datalogs.map((strategy, i) => (
                                    
                                    <div style={{margin: "23px 0px 0px 0px",width:"100%"}}>
                                        
                                        <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"14px", color: "white" }}>Strategy : </label> <label htmlFor="from_date" style={{margin: "0px 0px 0px 20px",  fontSize :"14px",fontWeight : "800" , color: "#FFC000" }}>{strategy['strategy_name']}</label> 
                                        <label htmlFor="from_date" style={{ margin: "0px 5px 0px 20px", fontSize: "14px", color: "white" }}>|</label>

                                        <label htmlFor="from_date" style={{ margin: "0px 0px 0px 5px", fontSize: "14px", color: "white" }}>Signal Type : </label> <label htmlFor="from_date" style={{ margin: "0px 0px 0px 5px", fontSize: "14px", fontWeight: "800", color: "#FFC000" }}>{strategy['signal_type']}</label>
                                        <label htmlFor="from_date" style={{ margin: "0px 5px 0px 20px", fontSize: "14px", color: "white" }}>|</label>

                                        <label htmlFor="from_date" style={{ margin: "0px 0px 0px 5px", fontSize: "14px", color: "white" }}>Entry Type : </label> <label htmlFor="from_date" style={{ margin: "0px 0px 0px 5px", fontSize: "14px", fontWeight: "800", color: "#FFC000" }}>{strategy['strategy_entry_type']}</label>
                                        <label htmlFor="from_date" style={{ margin: "0px 5px 0px 20px", fontSize: "14px", color: "white" }}>|</label>

                                        <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"14px", color: "white" }}>Exchange : </label> <label htmlFor="from_date" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", fontWeight : "800" , color: "#FFC000" }}>{strategy['exchange_name']}</label> 
                                        <label htmlFor="from_date" style={{ margin: "0px 5px 0px 20px", fontSize: "14px", color: "white" }}>|</label>

                                        <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"14px", color: "white" }}>Coin :  </label><label htmlFor="from_date" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", fontWeight : "800" , color: "#FFC000" }}>{strategy['coin_type']}</label> 
                                        <label htmlFor="from_date" style={{ margin: "0px 5px 0px 20px", fontSize: "14px", color: "white" }}>|</label>

                                        <label htmlFor="from_date" style={{ margin: "0px 0px 0px 5px", fontSize: "14px", color: "white" }}>Opti Name : </label> <label htmlFor="from_date" style={{ margin: "0px 0px 0px 5px", fontSize: "14px", fontWeight: "800", color: "#FFC000" }}>{strategy['opti_name']}</label>
                                        

                                        {strategy && strategy['time_frame'] && strategy['time_frame'].map((time_frame_sub, i) => (
                                        (
                                            <div style={{margin: "15px 0px 0px 0px" , color: "white" }}>
                                                
                                                {
                                                    time_frame_sub['row_data'] && time_frame_sub['row_data'].map((strategy_data, i) => (
                                                    <>

                                                        <div style={{margin: "10px 0px 0px 0px",width:"100%"}}>

                                                            <label htmlFor="from_date" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}>TF: </label><label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"14px", fontWeight : "800" , color: "#FFC000" }}>{strategy_data['time_frame']}</label> 

                                                            {/*<label htmlFor="from_date" style={{margin: "0px 10px 0px 20px",  fontSize :"14px", color: "white" }}>Basic S/L</label>
                                                            <label className="switch" >
                                                                <input type="checkbox" id={strategy_data.strategy_id+"_text_basic_sl"}
                                                                    checked={strategy_data.basic_sl === "true" ? true : false}  
                                                                    onChange={(e) => chage_status_basic_sl(e.currentTarget.checked, strategy_data.strategy_id)}/>  
                                                                <span className="slider round"></span>
                                                            </label>
                                                            */}

                                                            {/*
                                                            <label htmlFor="from_date" style={{margin: "0px 10px 0px 10px",  fontSize :"14px", color: "white" }}>AI S/L</label>
                                                            <label className="switch" >
                                                                <input type="checkbox" id={strategy_data.strategy_id+"_text_ai_sl"}
                                                                    checked={strategy_data.ai_sl === "true" ? true : false}  
                                                                    onChange={(e) => chage_status_ai_sl(e.currentTarget.checked, strategy_data.strategy_id)}/>  
                                                                <span className="slider round"></span>
                                                            </label>

                                                            <label htmlFor="from_date" style={{margin: "0px 10px 0px 10px",  fontSize :"14px", color: "white" }}>AI T/P</label>
                                                            <label className="switch" >
                                                                <input type="checkbox" id={strategy_data.strategy_id+"_text_ai_tp"}
                                                                    checked={strategy_data.ai_tp === "true" ? true : false}  
                                                                    onChange={(e) => chage_status_ai_tp(e.currentTarget.checked, strategy_data.strategy_id)}/>  
                                                                <span className="slider round"></span>
                                                            </label>
                                                            */}

                                                            <label htmlFor="from_date" style={{margin: "15px 10px 0px 15px",  fontSize :"14px", color: "white" }}>|</label> 

                                                            <label htmlFor="from_date" style={{margin: "0px 10px 0px 10px",  fontSize :"14px", color: "white" }}>SL/TP</label>
                                                            <Switch
                                                                id={strategy_data.strategy_id+"_text_ai_tp"}
                                                                checked={strategy_data.force_sl_tp === "true" ? true : false}   
                                                                onChange={(e) => chage_status_force_sl_tp(strategy_data.force_sl_tp === "true" ? true : false, strategy_data.strategy_id)}
                                                                className="react-switch"
                                                            />

                                                            {
                                                                strategy_data.force_sl_tp === "true"  && (
                                                                    <>
                                                                        <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "white" }}>Individual</label>
                                                                        <Switch
                                                                            id={strategy_data.strategy_id+"_text_individual"}
                                                                            checked={strategy_data.sl_tp_individual_last === "last" ? true : false}   
                                                                            onChange={(e) => chage_status_individual(strategy_data.sl_tp_individual_last === "last" ? true : false, strategy_data.strategy_id)}
                                                                            className="react-switch"
                                                                            handleDiameter={30}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                            height={20}
                                                                            width={48}
                                                                        />

                                                                        <label htmlFor="from_date" style={{margin: "0px 10px 0px 10px",  fontSize :"14px", color: "white" }}>Last</label>    
                                                                        {
                                                                        strategy_data.sl_tp_individual_last === "last"  && strategy_data.strategy_id && (
                                                                            <DevideSelector strategy_id={strategy_data.strategy_id} devide_type={strategy_data.devide_type} get_backtesting_status_all={get_backtesting_status_all} />
                                                                        )
                                                                        }
                                                                        
                                                                        <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  color: "#FFC000" }}>S/L:</label>  
                                                                        <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}>Short(%)</label>
                                                                        <input type="text" id={strategy_data.strategy_id+"_text_short_sl_html"}  name={strategy_data.strategy_id+"_text_short_sl_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={strategy_data.short_sl}  />

                                                                        <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}>Long(%)</label>
                                                                        <input type="text" id={strategy_data.strategy_id+"_text_long_sl_html"}  name={strategy_data.strategy_id+"_text_long_sl_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={strategy_data.long_sl}  />

                                                                        <label style={{margin: "0px 3px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "#FFC000" }}>T/P:</label>

                                                                        <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}>Short(%)</label>
                                                                        <input type="text" id={strategy_data.strategy_id+"_text_short_tp_html"}  name={strategy_data.strategy_id+"_text_short_tp_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={strategy_data.short_tp}  />

                                                                        <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}>Long(%)</label>
                                                                        <input type="text" id={strategy_data.strategy_id+"_text_long_tp_html"}  name={strategy_data.strategy_id+"_text_long_tp_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={strategy_data.long_tp}  />

                                                                        <button className="btn btn-sm" style={{ backgroundColor:"#FFBF00", color: "#000000" ,marginLeft: "auto" , margin: "0px 0px 0px 10px"}}  onClick={() => Update_SL_TP(strategy_data.strategy_id)}>Update</button>
                                                                        
                                                                        </>
                                                                )
                                                            }

                                                            {/* 
                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  color: "#FFC000" }}>Limit Count:</label>  
                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}></label>
                                                            <input type="text" id={strategy_data.strategy_id+"_text_consecutive_number_html"}  name={strategy_data.strategy_id+"_text_consecutive_number_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={strategy_data.consecutive_number}  />
                                                            <button className="btn btn-primary btn-sm" style={{ marginLeft: "auto" , margin: "0px 0px 0px 20px"}}  onClick={() => Update_consecutive_number(strategy_data.strategy_id)}>Update</button>
                                                            */}
                                                            
                                                            <label htmlFor="from_date" style={{margin: "0px 10px 0px 15px",  fontSize :"14px", color: "white" }}>|</label>         

                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  color: "#FFC000" }}>Short Order:</label>  
                                                            <input type="text" id={strategy_data.strategy_id+"_text_short_order_html"}  name={strategy_data.strategy_id+"_text_short_order_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={strategy_data.short_order}  />
                                                            <button className="btn btn-primary btn-sm" style={{ marginLeft: "auto" , margin: "0px 0px 0px 10px"}}  onClick={() => Update_short_order(strategy_data.strategy_id)}>Update</button>
                                                            

                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  color: "#FFC000" }}>Long Order:</label>  
                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}></label>
                                                            <input type="text" id={strategy_data.strategy_id+"_text_long_order_html"}  name={strategy_data.strategy_id+"_text_long_order_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={strategy_data.long_order}  />
                                                            <button className="btn btn-sm" style={{backgroundColor:"#FFBF00", color: "#000000" , marginLeft: "auto" , margin: "0px 0px 0px 10px"}}  onClick={() => Update_long_order(strategy_data.strategy_id)}>Update</button>
                                                            
                                                            <label htmlFor="from_date" style={{margin: "0px 10px 0px 15px",  fontSize :"14px", color: "white" }}>|</label>         
                                                            
                                                            <WalletSelector strategy_id={strategy_data.strategy_id} strategy_group={strategy_data.strategy_group} get_backtesting_status_all={get_backtesting_status_all} />
                                                                    
                                                            
                                                            <button className="btn btn-danger btn-sm" style={{ margin: "0px 0px 0px 30px" }} onClick={(e) => delete_live(strategy_data.strategy_id)}>
                                                                Delete
                                                            </button>


                                                        </div>
                                                        <div style={{margin: "10px 0px 10px 0px" ,width:"100%"}}>
                                                            <table style={{ fontSize :"14px"}}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{background: "#366CD0"}} >#</th>
                                                                    {/*<th style={{background: "#366CD0"}} >Stragety</th>
                                                                    <th style={{background: "#366CD0"}} >Coin</th>
                                                                    <th style={{background: "#366CD0"}} >Entry</th>
                                                                    <th style={{background: "#366CD0"}} >TF</th>*/}
                                                                    <th style={{background: "#366CD0"}} >From Date</th>
                                                                    <th style={{background: "#366CD0"}} >To Date</th>

                                                                    <th style={{background: "#366CD0"}} >Winning Ratio</th>
                                                                    <th style={{background: "#366CD0"}} >Change Ratio</th>
                                                                    {/*<th style={{background: "#366CD0"}} >Fiat Profit</th>*/}
                                                                    <th style={{background: "#366CD0"}} >Trades</th>
                                                                    <th style={{background: "#366CD0"}} >Last Position</th>
                                                                    
                                                                    <th style={{background: "#366CD0"}} >Optimized Time</th>
                                                                    <th style={{background: "#366CD0"}} >Status</th>
                                                                    <th style={{background: "#366CD0"}} >Details</th>
                                                                    <th style={{background: "#366CD0"}} >Optimize</th>
                                                                    <th style={{background: "#366CD0"}} >Positioning</th>
                                                                    <th style={{background: "#366CD0"}} >Live Trading</th>
                                                                    <th style={{background: "#366CD0"}} >Reset</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="logTr" key={i} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                                                    <td>{i + 1}</td>
                                                                    {/*<td>{strategy_data.strategy_name.toUpperCase()}</td>
                                                                    <td>{strategy_data.coin_type.toUpperCase()}</td>
                                                                    <td>{strategy_data.entry_direction.toUpperCase()}</td>
                                                                    <td>{strategy_data.time_frame}</td>*/}
                                                                    <td>{strategy_data.backtesting_from_date}</td>
                                                                    <td>{strategy_data.backtesting_to_date}</td>
                                                                    
                                                                    <td>{parseFloat(strategy_data.profitable_trade).toFixed(1)} %</td>
                                                                    <td>{parseFloat(strategy_data.increased_asset_ratio).toFixed(1)} %</td>
                                                                    {/*<td>{parseFloat(strategy_data.profit_fiat_ratio).toFixed(1)} %</td>*/}
                                                                    <td>{strategy_data.tradeCount}</td>

                                                                    <td>{strategy_data.last_position}</td>
                                                                    <td>{strategy_data.update_time}</td>
                                                                    <td>{strategy_data.operation}</td>
                                                                    <td><button className="btn btn-sm" onClick={() => ViewChart(strategy_data.strategy_id)} style={{backgroundColor:"#FFBF00", color: "#000000" , marginLeft: "auto" }}>View</button></td> 
                                                                    <td>
                                                                        {/*
                                                                        <label className="switch" >
                                                                            <input type="checkbox" id={strategy_data.strategy_id+"_checked_optimize"}
                                                                                checked={strategy_data.operation === "running" ? true : false}  
                                                                                onChange={(e) => update_live_trading_opti(e.currentTarget.checked, strategy_data.strategy_id)}/>  
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                        */}
                                                                        <Switch
                                                                            id={strategy_data.strategy_id+"_checked_optimize"}
                                                                            checked={strategy_data.operation === "running" ? true : false}  
                                                                            onChange={(e) => update_live_trading_opti(strategy_data.operation === "running" ? true : false, strategy_data.strategy_id)}
                                                                            className="react-switch"
                                                                        />
                                                                        
                                                                    </td>
                                                                    <td>
                                                                        {/*
                                                                        <label className="switch" >
                                                                            <input type="checkbox" id={strategy_data.strategy_id+"_checked_live_trading"}
                                                                                checked={strategy_data.trading_status === "running" ? true : false}  
                                                                                onChange={(e) =>Stop_Trading(e.currentTarget.checked, strategy_data.strategy_id)}/>  
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                        */}
                                                                        <Switch
                                                                            id={strategy_data.strategy_id+"_checked_live_trading"}
                                                                            checked={strategy_data.trading_status === "running" ? true : false}  
                                                                            onChange={(e) => Stop_Trading(strategy_data.trading_status === "running" ? true : false, strategy_data.strategy_id)}
                                                                            className="react-switch"
                                                                        />
                                                                        
                                                                    </td>
                                                                    <td>
                                                                     {/*
                                                                        <label className="switch" >
                                                                            <input type="checkbox" id={strategy_data.strategy_id+"_text_to_sentinel"}
                                                                            checked={strategy_data.to_sentinel === "true" ? true : false}  
                                                                            onChange={(e) => chage_status_to_sentinel(e.currentTarget.checked, strategy_data.strategy_id)}/>  
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                        */}
                                                                        <Switch
                                                                             id={strategy_data.strategy_id+"_text_to_sentinel"}
                                                                            checked={strategy_data.to_sentinel === "true" ? true : false}  
                                                                            onChange={(e) => chage_status_to_sentinel(strategy_data.to_sentinel === "true" ? true : false, strategy_data.strategy_id)}
                                                                            className="react-switch"
                                                                        />
                                                                    </td>
                                                                    <td><button className="btn btn-sm" onClick={() => Reset_LiveData(strategy_data.strategy_id)} style={{backgroundColor:"#FFBF00", color: "#000000" , marginLeft: "auto" }}>Reset</button></td> 
                                                                </tr>
                                                            </tbody>
                                                            </table>
                                                        </div>
                                                    
                                                        
                                                    </>
                                                    
                                            ))

                                            }

                                        </div>
                                        
                                        )
                                        ))}
                                    <>
                                    <hr style={{ margin: "0px 0px 30px 0px" , width:"100%", height:"2px" , border:"2px solid #FFC000" , borderRadius:"2px" }} ></hr>
                                    </>
                                
                                    </div>
                                ))}
                                {/* /////////////////////////////////////////////////////// strategy /////////////////////////////////////////////////////// */}

                                
                            </div>

                        </div>
                    ) }
                </div>
            </div>
            

            <ModalChart
                    open={modalOpen}
                    close={closeModal}
                    header={
                        <>
                            <button className="close" onClick={closeModal}>
                                {" "}
                                &times;{" "}
                            </button>
                        </>
                    }> 
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <div style={{ width: "100%", overflowY :"scroll" , height:"900px"}}>
                        {/* ////////////////////////////////////////  */}
                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>

                            <div style={{margin: "0px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>[ Strategy : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{strategy_name.toUpperCase()}</label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>Exchange : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{exchange_name.toUpperCase()}</label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>Coin : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{coin_type.toUpperCase()} </label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>TF : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}> {time_frame}</label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>Entry Direction : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{entry_direction.toUpperCase()}</label>  ]</label> 
                                
                            </div>
                            <div style={{margin: "10px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <li>{dataItemToKeyValues(parameter_json)}</li>
                            </div> 
                        
                        </div>

                        <div style={{ width: "100%", overflowY :"scroll" , height:"900px"}}>
                        {/* ////////////////////////////////////////  */}
                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <div style={{margin: "0px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>[ Stop Loss detection ]</label> 
                            </div>
                            <div style={{margin: "10px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <li>{dataItemToKeyValues_SL(sl_status)}</li>
                            </div> 
                        
                        </div>


                        {/* ////////////////////////////////////////  */}
                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <div style={{margin: "0px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>[ AI Prediction : S/L, T/P ]</label> 
                            </div>
                            <div style={{margin: "10px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <li>{dataItemToKeyValues_AI_SL_TP(ai_sl_tp_status)}</li>
                            </div> 
                        
                        </div>


                        
                        {/* ////////////////////////////////////////  */}
                        {/* ////////////////////////////////////////  */}
                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <div style={{margin: "0px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>[ Direction ]</label> 
                            </div>
                            <div style={{margin: "10px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <li>{dataItemToKeyValues_Direction(direction_status)}</li>
                            </div> 
                        
                        </div>

                        
                        {/* ////////////////////////////////////////  */}


                        <div style={{margin: "1px 0px 0px 0px"}}>
                            <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"1px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            {result_file_path !== ""  ? 
                                <>
                                <div style={{margin: "10px 0px 0px 0px" ,width:"99%" , background:"#1c1b2b"}}>
                                    <TradingView strategy_id={strategy_id}  type={"live"}   result_file_path={result_file_path} strategy_name={strategy_name} exchange_name={exchange_name} coin_type={coin_type} time_frame={time_frame} start_trade_time={start_trade_time}></TradingView>
                                </div>
                                </>
                                : null}
                            </div>
                        </div>
                        <div style={{margin: "1px 0px 0px 0px"}}>
                            <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"1px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>

                            {result_file_path !== ""  ? 
                                <>
                                <div style={{margin: "10px 0px 0px 0px" ,width:"99%" , background:"#1c1b2b"}}>
                                    <TradingLog strategy_id={strategy_id}  type={"live"}   result_file_path={result_file_path} strategy_name={strategy_name} exchange_name={exchange_name} coin_type={coin_type} time_frame={time_frame}></TradingLog>
                                </div>
                                </>
                                : null}
                            </div>
                        </div>
                        {/* ////////////////////////////////////////  */}
                    </div>

                    
                    </div>
            </ModalChart>
            

        </div>
    );
}

export default BackTesting_Simuation;
